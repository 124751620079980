<template>
    <div>
        <!-- <div id="cerchio"></div> -->
        <Landing />
        <Words />
        <Ffs />
    </div>
</template>
<script>
import Words from './Words'
import Landing from './Landing'
import Ffs from './Ffs'

export default {
    name: 'Home',
    data() {
        return {
            interval_1: null,
            timeout_1: null,
            timeout_2: null,
            timeout_3: null
        }
    },
    components: {
        Words,
        Landing,
        Ffs
    },
    computed: {
        page() {
            return this.$store.state.page;
        },
        ffs() {
            return this.$store.state.ffs;
        }
    },
    mounted() {


        // //dopo 30  s la tolgo
        // setInterval(() => {
        //     switch (this.page) {
        //         case '1':
        //             this.$store.commit('setPage', '2')
        //             break;
        //         default:
        //             this.$store.commit('setPage', '2')
        //     }
        // }, 10000 * 3)
        // // 0000 * 6 - > 1 min

        // //dopo 5 minuto rimetto la prima
        // setInterval(() => {
        //     switch (this.page) {
        //         case '2':
        //             this.$store.commit('setPage', '1')
        //             break;
        //         default:
        //             this.$store.commit('setPage', '1')
        //     }
        // }, 10000 * 6 * 2)


        // //timetable scompare
        // setInterval(() => {
        //     console.log("ffs interval 2");
        //     switch (this.ffs) {
        //         case '1':
        //             console.log("ffs out");
        //             this.$store.commit('setFfs', '0')
        //             break;
        //         default:
        //             // this.$store.commit('setFfs', '0')
        //     }
        // }, 10000 * 4 - 100)


        // //timetable compare
        // setInterval(() => {
        //     console.log("ffs interval 1");
        //     switch (this.ffs) {
        //         case '0':
        //             console.log("ffs in");
        //             this.$store.commit('setFfs', '1')
        //             break;
        //         default:
        //             // this.$store.commit('setFfs', '1')
        //     }
        // }, 10000 * 6 * 1)
        // // 1000 * 6 * 3s





        // setTimeout(() => {
        //     this.$store.commit('setFfs', '1')
        // }, 4000)

        this.path();
        // this.interval_1 = setInterval(() => {
        //     this.path();
        // }, 1000 * 35)

    },
    methods: {
        path() {



            this.timeout_1 = setTimeout(() => {
                this.$store.commit('setPage', '2')
            }, 1000 * 30)

            this.timeout_1 = setTimeout(() => {
                this.$store.commit('setFfs', '1')
            }, 1000 * 90)

            this.timeout_1 = setTimeout(() => {
                this.$store.commit('setFfs', '2')
                this.$store.commit('setPage', '1')

                clearTimeout(this.timeout_1);
                clearTimeout(this.timeout_2);
                clearTimeout(this.timeout_3);

                this.path();

            }, 1000 * 120)
        }
    },
    watch: {
        $route(to) {}
    }
}
</script>
<style lang="scss">
</style>