<template>
    <div id="words" v-bind:class="{active : page == '2'}">
        <h1 id="text"></h1>
        <h2 id="title" ref="title" class="bottom">
            {{title}}
        </h2>
    </div>
</template>
<script>
import TypeIt from "typeit";
import json from "../assets/data/data.json";

export default {
    data() {
        return {
            sliders: json.sliders,
            instance: null,
            num_slide: 0,
            isFinish: false,
            title: ""
        }
    },
    mounted() {
        this.type()
        console.log(this.sliders);
    },
    components: {

    },
    computed: {
        page() {
            return this.$store.state.page;
        }
    },
    methods: {
        type() {
            this.typeIt();
            this.title = this.sliders[this.num_slide].title;
            this.instance.type(this.sliders[this.num_slide].text).pause(4000).delete(null, { delay: 4000 }).go();
        },
        typeIt() {
            let self = this;
            this.instance = new TypeIt("#text", {
                speed: 150,
                waitUntilVisible: false,
                breakLines: false,
                cursor: false,
                loop: false,
                afterComplete: async (step, instance) => {
                    self.instance.destroy()
                    // console.log(self.sliders[self.num_slide + 1].text);
                    self.isFinish = true;
                    if (self.num_slide < self.sliders.length - 1) {
                        self.num_slide++;
                    } else {
                        self.num_slide = 0;
                    }
                }

            })
        },
    },
    watch: {
        isFinish(n) {
            if (n == true) {
                this.isFinish = false;
                this.type()
            }
        }
    },
};
</script>