import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        page: '1',
        ffs: '0',
    },
    mutations: {
        setPage(state, val) {
            state.page = val;
        },
        setFfs(state, val) {
            state.ffs = val;
        },
    }

})

export default store;