<template>
    <div id="ffs" v-bind:class="{active : ffs == '1'}">
        <h1 id="">
            SBB CFF FFS<br />
        </h1>
        <div id="timetable" v-if="data" class="bottom">
            <div class="timetable-item timetable-label ">
                <div>
                    <p>Orario</p>
                </div>
                <div>
                    <p></p>
                </div>
                <div>
                    <p>Direzione</p>
                </div>
                <div>
                    <p>B</p>
                </div>
                <!-- {{item.stop.station.name}} -->
            </div>
            <div v-for="(item, key) in data.stationboard" :key="key" class="timetable-item filetto">
                <div>
                    <p>{{item.stop.departureTimestamp| getTime}}</p>
                </div>
                <div>
                    <p class="timetable-category" :class="item.category">{{item.category}}</p>
                </div>
                <div>
                    <p>{{item.to}}</p>
                </div>
                <div>
                    <p>{{item.stop.platform}}</p>
                </div>
                <!-- {{item.stop.station.name}} -->
            </div>
        </div>
        <div v-else>
            Nessuna informazione
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            data: null,
            pending: true,
            error: false
        }
    },
    mounted() {
        this.requestData()
        setInterval(() => {
            this.requestData()
        }, 30000)
    },
    computed: {
        page() {
            return this.$store.state.page;
        },
        ffs() {
            return this.$store.state.ffs;
        }
    },
    components: {

    },
    filters: {
        capitalize: function(value) {
            if (!value) return ''
            value = value.toString()
            return value.toUpperCase()
        },
        getTime: function(value) {

            let unix_timestamp = value
            let date = new Date(unix_timestamp * 1000);
            let hours = date.getHours();
            let minutes = "0" + date.getMinutes();
            let seconds = "0" + date.getSeconds();
            let formattedTime = hours + ':' + minutes.substr(-2);
            return formattedTime;
        }
    },
    methods: {
        async requestData() {
            this.pending = true
            try {
                const { data } = await axios.get("https://transport.opendata.ch/v1/stationboard", {
                    params: { station: 'Mendrisio', limit: 6 }
                })
                this.data = data;
                this.error = false;
            } catch (e) {
                this.data = null;
                this.error = e;
            }

        }
    },
    watch: {

    },
};
</script>