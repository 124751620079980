<template>
    <div id="app">
        <div id="wrapper">
            <router-view></router-view>
        </div>
    </div>
</template>
<script>
export default {
    name: 'App',
    data() {
        return {
            load: true,
        }
    },
    computed: {

    },
    components: {},
    mounted() {

    },
    methods: {

    },
    watch: {

    }
}
</script>