import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'

import store from "./store"
import './css/main.scss'
import Home from './components/Home.vue'
Vue.config.productionTip = false
Vue.config.silent = true
Vue.use(VueRouter)


const routes = [{
        path: '/',
        component: Home,
        name: 'home'
    },
    {
        path: '/',
        component: Home,
        children: [
            // {
            //         path: '/animation',
            //         name: 'animation',
            //         component: Animation
            //     },
            //     {
            //         path: '/info',
            //         name: 'info',
            //         component: Info
            //     },
            // {
            //     path: '/programme',
            //     name: 'programme',
            //     component: Programme
            // },
            // {
            //     path: '/mentors',
            //     name: 'mentors',
            //     component: Mentors
            // },
            // {
            //     path: '/artists',
            //     name: 'artists',
            //     component: Artists
            // }
        ]
    }
]

const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { x: 0, y: 0 };
    }
})


new Vue({
    render: h => h(App),
    store,
    router
}).$mount('#app')