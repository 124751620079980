<template>
    <div id="landing" v-bind:class="{active : page == '1'}">
        <h1 id="">
            Dipartimento ambiente costruzioni e design
        </h1>
        <h2 id="" ref="" class="bottom">
            <img src="../assets/img/logo.svg" alt="logo" />
        </h2>
    </div>
</template>
<script>
export default {
    data() {
        return {}
    },
    mounted() {

    },
    computed: {
        page() {
            return this.$store.state.page;
        }
    },
    components: {

    },
    methods: {

    },
    watch: {

    },
};
</script>